.scs-tooltip-error { 
    // the error tooltip is positioned poorly by OCM as of 22.8.1
    transform: translate( 0px, -50px);
}

img[data-mce-style="float: left;"] {
    // fix for images that are floated left in the RTE
    float: left;
    margin-right: 1rem;
}

img[data-mce-style="float: right;"] {
    // fix for images that are floated right in the RTE
    float: right;
    margin-left: 1rem;
}