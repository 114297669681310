@use '../../../../themes/BHE-Public-SiteTheme/assets/scss/utilities/utilities' as *;



.layout-shell {
    width: 100%;
    overflow-x: hidden;

    // remove the thin white line between components at certain screen sizes
    &:not(.scs-edit-mode) {
        .scs-component-bounding-box {
            padding: 0;
            -webkit-padding-before: 0;
            -webkit-padding-after: 0;
            -webkit-padding-start: 0;
            -webkit-padding-end: 0;
        }
    }

    #slot-header {
        // max-width: var(--header-width);
        // margin-inline: auto;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 11;
    }
    
    #slot-hero{
        max-width: none;
        margin-inline: auto;

        img:not(.hero-bar, .logo, .hero-banner, .icon-img){
            // width: 100%;
            // filter: drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.25));
            max-height: 20rem;
            height: 20rem;
            object-fit: cover;
            
        }

        @include mq(mobile-large) {
            img:not(.hero-bar, .logo, .hero-banner, .icon-img)  {
            height: 16.25rem;
            min-height: 16.25rem;
            }
        }
        
    }

    #under-hero,
    #slot-content {
        max-width: var(--page-width);
        margin-inline: auto;

    }
    
    #slot-hero,
    #under-hero,
    #slot-content,
    #slot-footer {
        margin-top: 1rem;
    }
    #slot-header,
    #slot-hero,
    #under-hero,
    #slot-content,
    #slot-footer {
        @include mq(tablet) {
            width: unset;
            max-width: 100%;
            padding-inline: 0;
        }
    }

    #slot-footer {
        margin-inline: auto;
    }

    #slot-content,
    #under-hero {
        @include mq(desktop) {
            margin-inline: auto;
            padding-inline: 4rem;
        }
        
        @include mq(tablet) {
            margin-inline: 4rem;
            padding-inline: 0;
            width: unset;
            max-width: 100%;
        }

        @include mq(mobile-large) {
            margin-inline: 1rem;
        }
    }

    #under-hero {
        display: flex;
        justify-content: space-between;
        #layout-breadcrumbs {
            margin-left: 5px;
        }
        #share-widget {
            margin-right: 5px;
        }
        // @include mq(tablet) {
        //     margin-inline: 1.25rem;
        //     width: unset;
        //     max-width: 100%;
        // }

        // @include mq(mobile-large) {
        //     margin-inline: 1.625rem;
        // }
    }

    #slot-hero {
        padding-inline: 0;
        font-family: var(--font-heading);

        @include mq(tablet) {
            margin-block: 1rem;
            width: unset;
            max-width: 100%;
        }

        @include mq(mobile-large) {
            margin: unset;
        }

        .scs-image-container {
            overflow: visible;

            img {
                width: 100%;
                height: auto;
                // border-bottom: 0.5rem solid var(--color-grey-medium);
                filter: drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.25));
            }

            // &:after {
            //     content: '';
            //     position: absolute;
            //     top: 1rem;
            //     right: 1rem;
            //     --size: 10%;
            //     width: var(--size);
            //     height: 100%;
            //     background-image: url(../img/trigon.svg);
            //     background-repeat: no-repeat;
            //     background-size: contain;
            //     overflow: hidden;
            //     opacity: 0.15;
            // }
        }
    }

    .scs-image-default-style {
        @include mq(tablet) {
            text-align: center;
        }
    }
}
