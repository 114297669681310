$breakpoints: (
  'mobile-small': 428px,
  'mobile-large': 768px,
  'tablet-small': 1000px,
  'tablet': 1250px,
  'desktop': 1440px
);

// -------- Media Queries Function -------- //

$print-media: false !default;

// Usage: mq(breakpoint/medium, min or max)
@mixin mq($point, $type: "max") {
  @if $print-media {
    @if $point == "print" {
      @media print {
        @content;
      }
    } @else {
      @content;
    }
  } @else {
    $breakpoint: null;
    @if type-of($point) == "number" {
      @if unitless($point) or unit($point) == "em" or unit($point) == "rem" or unit($point) == "%" {
        @warn "Please use pixel values.";
      } @else {
        $breakpoint: $point;
      }
    } @else if map-has-key($breakpoints, $point) {
      $breakpoint: map-get($breakpoints, $point);
    } @else {
      @warn "Couldn't find a breakpoint named `#{$point}`.";
    }

    @if $breakpoint != null {
      @if $type == "max" {
        @media screen and (max-width: ($breakpoint - 1px)) {
          @content;
        }
      } @else {
        @media screen and (min-width: ($breakpoint)) {
          @content;
        }
      }
    }
  }
}