// @use 'utilities/reset';
@use 'index' as *;

@forward './layouts/shell';

html {
	scroll-behavior: smooth;
	box-sizing: border-box;
	a {
		color: var(--color-black);
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
	.skip-link {
		position: absolute;
		top: -10rem;
		background: white;
		color: black;
		padding: 2rem;
		&:focus {
		  top: 0;
		}
	  }
}