@media screen and (prefers-reduced-motion: reduce) {
  * {
    /* Very short durations means JavaScript that relies on events still works */
    animation-duration: 0s !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0s !important;
  }
}

@media (inverted-colors) {
  img, video { 
    filter: invert(100%);
  }
}

@mixin high-contrast {
  --color-primary-dark: #9E0000;
  --color-primary: var(--color-primary-dark);
  --color-primary-light: var(--color-primary-dark);

  --color-secondary: #f2f2f2;
  --color-secondary-dark: var(--color-secondary);

  --color-tertiary: var(--color-black);

  --color-principle-light-blue: #80bcc9;
  --color-principle-red: #B71F25;
  --color-principle-green: #046839;
  --color-principle-dark-gray: #313131;
  --color-principle-blue: #0063A6;
  --color-principle-gray: #57585B;

  --color-black: #121212;
  --color-grey-dark: var(--color-black);
  --color-gray-dark: var(--color-grey-dark);
  --color-grey-medium: var(--color-black);
  --color-gray-medium: var(--color-grey-medium);

  --color-off-white: #f6f6f6;
  --color-grey-light: #e8e8e8;
  --color-gray-light: var(--color-grey-light);
  --color-white: #ffffff;

  --color-success: #347e37;
  --color-danger: #cf2b2b;
  --color-warning: #e8a232;
}