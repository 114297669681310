#layout-breadcrumbs {
    // padding: var(--page-padding) var(--page-padding) 0.5rem var(--page-padding);
    // max-width: var(--page-width);
    // margin: 0 auto;

    ol {
        margin: 0;
        padding-left: 0;
        list-style: none;
        // display: inline-block;
    }

    li {
        display: inline;
    }

    li+li::before {
        display: inline-block;
        margin: 0 0.25em;
        // transform: rotate(15deg);
        // border-right: 0.1em solid currentcolor;
        height: 0.8em;
        content: ">";
        font-weight: initial;
        color: var(--color-grey-medium);
    }

    [aria-current="page"] {
        color: #000;
        font-weight: 700;
        text-decoration: none;
    }
}