@forward './components/colors';
@forward './components/typography';
@forward './components/breadcrumbs';
@forward './components/share';
@forward './components/icons';

@forward './utilities/accessibility';
@forward './utilities/mediaqueries';
@forward './utilities/reset';
@forward './utilities/scsreset';
// @forward './utilities/utilities';

:root {
    --header-width: 1440px;
    --page-width: 1250px;
    --page-padding: 1rem;
}