@use '../../../../themes/BHE-Public-SiteTheme/assets/scss/utilities/utilities' as *;

:root {
    font-size: 16px;
    --font-heading: "museo-slab-n3", "museo-slab", Georgia, "Times New Roman", Times, serif;
    --font-body: "proxima-nova-n4", "proxima-nova", Arial, Helvetica, sans-serif;
}

body {
    // Body Text //
    color: var(--color-black);
    font-family: var(--font-body);
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 140%;

    strong,
    .bold {
        font-weight: 700;
    }

    em,
    .emphasized {
        font-style: italic;
    }

    sm,
    .small {
        // Actual specified font size is 14px, this is 14.4px on standard body text
        font-size: 0.9em;
    }

    .subtitle,
    .pre-title {
        font-weight: 700;
        font-size: 1.5rem;
        letter-spacing: 0.03em;
    }

    .subtitle {
        line-height: 1.5em;
    }

    .pre-title {
        line-height: 1.1em;
    }

    // Headings //
    %heading {
        font-family: var(--font-heading);
        font-weight: 300;
        line-height: 1.2;
        padding-block: 0.5rem;

        @include mq(mobile-small) {
            line-height: 1.3 !important;
        }
    }

    %subheading {
        @extend %heading;
        font-family: var(--font-heading);
    }

    h1 {
        @extend %heading;
        font-size: 2.625rem;

        @include mq(mobile-small) {
            font-size: 2.5rem !important;
        }
    }

    h2 {
        @extend %subheading;
        font-size: 2.25rem;

        @include mq(mobile-small) {
            font-size: 2rem;
        }
    }

    h3 {
        @extend %subheading;
        font-size: 1.75rem;

        @include mq(mobile-small) {
            font-size: 1.5rem !important;
        }
    }

    h4 {
        @extend %subheading;
        font-size: 1.5rem;

        @include mq(mobile-small) {
            font-size: 1.25rem !important;
        }
    }

    h5 {
        @extend %subheading;
        font-size: 1.25rem;

        @include mq(mobile-small) {
            font-size: 1rem !important;
        }
    }

    h6 {
        @extend %subheading;
        font-size: 1.125rem ;

        @include mq(mobile-small) {
            font-size: 0.75rem !important;
        }
    }

    // Form elements //
    button,
    .button {
        font-weight: 700;

        &:hover {
            text-decoration: underline;
        }
    }
}