// @use '../utilities/accessibility';

:root {
    --color-primary: #ea0029;
    --color-primary-light: #FF1A43;
    --color-primary-dark: #9E0000;

    --color-secondary: #f2f2f2;
    --color-secondary-dark: #e5e5e5;

    --color-tertiary: #212529;

    --color-secondary-button: #4D5054;
    --color-secondary-button-hover: #CED2D6;

    --color-principle-light-blue: #80bcc9;
    --color-principle-red: #B71F25;
    --color-principle-green: #046839;
    --color-principle-dark-gray: #313131;
    --color-principle-blue: #0063A6;
    --color-principle-gray: #57585B;

    --color-black: #121212;
    --color-grey-dark: #212529;
    --color-gray-dark: var(--color-grey-dark);
    --color-grey-medium: #7e8082;
    --color-gray-medium: var(--color-grey-medium);

    --color-banner-gray-light: #dddddd;
    --color-banner-gray: #787878;
    --color-banner-black: #4D5054;
    --color-banner-gray-accent: #cccccc;

    --color-off-white: #f6f6f6;
    --color-grey-light: #ced2d6;
    --color-gray-light: var(--color-grey-light);
    --color-gray-extra-light: #f2f2f2;
    --color-grey-extra-light: var(--color-gray-extra-light);
    --color-white: #ffffff;

    --color-success: #347e37;
    --color-danger: #cf2b2b;
    --color-warning: #e8a232;
}

// @media (prefers-contrast: high) {
//     :root {
//         @include accessibility.high-contrast;
//     }
//   }