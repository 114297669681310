// Format later
#share-widget {
    display: flex;
    position: relative;
    
    > div:first-child {
        font-weight: bold;
        color: var(--color-grey-dark);
        cursor: pointer;
    }

    .material-symbols-outlined {
        vertical-align: middle;
        &.open {
            padding-right: 0.5rem;
        }
        &.close {
            margin-left: auto;
        }
    }

    .share-btn-link-div {
        display: flex;
        align-items: center;
        color: var(--color-grey-medium);
        cursor: pointer;
    }

    .share-popup-window {
        border: 1px solid var(--color-grey-light);
        width: 0px;
        height: 0px;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
        background-color: var(--color-white);
        overflow: hidden;
        transition-property: width, height, opacity;
        transition-duration: 0.25s;

        &.open {
            width: 6.875rem;
            height: 7.5rem;
            opacity: 1;
        }

        .share-popup-list {
            display: flex;
            flex-direction: column;
            padding-inline-start: unset;
            margin: 0.5rem;
            font-size: 1rem;

            & div:first-child {
                color: var(--color-grey-medium);
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0.25rem;

                span {
                    color: #4d5054;
                    cursor: pointer;
                }
            }

            & div:nth-child(2),
            & div:nth-child(3) {
                border-bottom: 1px solid var(--color-grey-light);
            }

            & div a {
                color: var(--color-banner-black);
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
                span:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}